import { FormGroup, Label, Input } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';
import SingleFileUpload from './SingleFileUpload';
import Tooltip from './RenderTooltip'; // Import the Tooltip component
import InputMask from 'react-input-mask';

function renderField(field, handleChange, formData, viewOnly) {
    // Check if field name contains "remark" (case insensitive)
    const isRemarkField = field.name.toLowerCase().includes("remark");

    // Check if the field is a remark field and has no value in formData
    if (isRemarkField && !formData[field.name]) {
        return null; // Return null to hide the field if it has no data
    }

    // Apply warning style for remark fields
    const inputClass = isRemarkField ? 'form-control bg-warning text-dark' : 'form-control';

    // Render tooltip if tooltipText is available
    const tooltip = field.tooltip ? <Tooltip text={field.tooltip} targetId={`tooltip-${field.name}`} /> : null;

    // Apply ViewOnly flag to mark fields as read-only
    const isViewOnly = (field.ViewOnly === true || viewOnly === true || viewOnly === 'true');

    // Regex for float validation (optional "-" for negative, digits, and one decimal point)
    const floatRegex = /^-?\d*\.?\d*$/;

    switch (field.type) {
        case 'text':
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    <Input
                        className={inputClass}
                        type="text"
                        name={field.name}
                        placeholder={field.placeholder}
                        onChange={handleChange}
                        required={field.required === true}
                        value={formData[field.name] || ''}
                        readOnly={isRemarkField || isViewOnly}  // Make field read-only for remark fields or if ViewOnly is true
                    />
                </>
            );
        case 'float':
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    <InputMask
                        mask="999.99" // Adjust to desired float format
                        maskChar="" // Allows unrestricted input for unused positions
                        className={inputClass}
                        type="text"
                        name={field.name}
                        placeholder={field.placeholder}
                        required={field.required}
                        value={formData[field.name] || ''}
                        readOnly={isRemarkField || isViewOnly}
                        onChange={handleChange} // Directly update the formData state without extra validation
                    />
                </>
            );
        case 'textarea':
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    <Input
                        className={inputClass}
                        type="textarea"
                        name={field.name}
                        rows="3"
                        placeholder={field.placeholder}
                        onChange={handleChange}
                        required={field.required}
                        value={formData[field.name] || ''}
                        readOnly={isRemarkField || isViewOnly}  // Make field read-only for remark fields or if ViewOnly is true
                    />
                </>
            );
        case 'select':
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    <select
                        className={inputClass}
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={handleChange}
                        required={field.required}
                        disabled={isRemarkField || field.readOnly || isViewOnly}  // Disable if remark field, readOnly or ViewOnly is true
                    >
                        <option value="">Select...</option>
                        {field.options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </>
            );
        case 'radio':
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    {field.options.map((option, index) => (
                        <FormGroup check key={index}>
                            <Label check>
                                <Input
                                    className="form-control"
                                    type="radio"
                                    name={field.name}
                                    value={option.value}
                                    onChange={handleChange}
                                    required={field.required}
                                    checked={formData[field.name] === option.value}
                                    disabled={isRemarkField || isViewOnly}  // Disable if it's a remark field or ViewOnly is true
                                />{' '}
                                {option.label}
                            </Label>
                        </FormGroup>
                    ))}
                </>
            );
        case 'checkbox':
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    <FormGroup check>
                        <Label check>
                            <Input
                                className="form-control"
                                type="checkbox"
                                name={field.name}
                                onChange={handleChange}
                                required={field.required}
                                checked={formData[field.name]}
                                disabled={isRemarkField || isViewOnly}  // Disable checkboxes for remark fields or if ViewOnly is true
                            />{' '}
                            {field.label}
                        </Label>
                    </FormGroup>
                </>
            );
        case 'file':
            const idkey = field.name + "Id";
            const namekey = field.name + "Name";
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    <SingleFileUpload
                        title={field.label}
                        name={field.name}
                        fileId={formData ? formData[idkey] : null}
                        fileName={formData ? formData[namekey] : null}
                        relatedId={formData ? formData.id : null}
                        disabled={isRemarkField || isViewOnly}  // Disable file upload for remark fields or ViewOnly is true
                    />
                </>
            );
        case 'currency':
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    <CurrencyInput
                        className={inputClass}
                        name={field.name}
                        placeholder={field.placeholder}
                        decimalsLimit={0}
                        required={field.required}
                        prefix={field.currencySymbol || '₹'}  // Display currency symbol (default INR)
                        value={formData[field.name] || ''}
                        onValueChange={(value, name) => {
                            handleChange({ target: { name: field.name, value } });  // Update currency value in formData
                        }}
                        intlConfig={{ locale: 'en-IN', currency: field.currencyType || 'INR' }} // Config for INR locale
                        readOnly={isRemarkField || isViewOnly}  // Mark currency fields as read-only if it's a remark field or ViewOnly is true
                    />
                </>
            );
        default:
            return (
                <>
                    <Label>
                        {field.label}
                        {tooltip}
                    </Label>
                    <Input
                        className={inputClass}
                        type={field.type}
                        name={field.name}
                        placeholder={field.placeholder}
                        onChange={handleChange}
                        required={field.required}
                        readOnly={isRemarkField || field.readOnly || isViewOnly}  // Mark field as read-only if it's a remark field, readOnly, or ViewOnly is true
                        value={formData[field.name] || ''}
                    />
                </>
            );
    }
}

export default renderField;
