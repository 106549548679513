import React, { useState, useEffect } from 'react';
import { fetchApplicantByEmail, createApplicant, updateApplicant } from '../../helpers/Espo/applicant_helper';
import { Button, Collapse, Card, CardBody, CardHeader, Col, Row, CardTitle } from 'reactstrap';
import classnames from 'classnames';
import DynamicForm from '../Forms/DynamicForm';
import ApplicationList from '../Application/ApplicationList';

const Applicant = () => {
    const [applicantData, setApplicantData] = useState(null); // Initialize as null
    const [loading, setLoading] = useState(true);
    const [applicantExists, setApplicantExists] = useState(false);
    const [email, setEmail] = useState("");
    const [col1, setCol1] = useState(false);

    // Fetch User Email
    useEffect(() => {
        const authUser = localStorage.getItem("authUser");
        if (authUser) {
            const obj = JSON.parse(authUser);
            setEmail(obj.email);
        }
    }, []);

    // Load applicant data
    useEffect(() => {
        const loadApplicant = async () => {
            try {
                if (email) {
                    const response = await fetchApplicantByEmail(email);

                    if (response && response.total > 0) {
                        console.log("loadApplicant Response - ", response.list[0]);
                        setApplicantData(response.list[0]);
                        setApplicantExists(true);
                    } else {
                        setApplicantData({ emailAddress: email });
                        setApplicantExists(false);
                        setCol1(true);
                    }
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        loadApplicant();
    }, [email]);

    // Pass formData to this function
    const handleSubmit = async (formData) => {
        try {
            // Use formData from DynamicForm to update or create the applicant
            if (applicantExists) {
                await updateApplicant(applicantData.id, formData);
                alert("Applicant updated successfully!");
            } else {
                await createApplicant(formData);
                alert("New applicant created successfully!");
                setApplicantExists(true);
            }
        } catch (error) {
            console.error(error);
            alert("Failed to save applicant.");
        }
    };

    if (loading) return <div>Loading...</div>; // Show loading state until data is loaded

    const toggleCol1 = () => {
        setCol1(!col1);
    };

    const aidApplicantSchema = require('../Forms/Schema/AidApplicant.json');

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="accordion" id="accordion">
                        <CardTitle hidden={applicantExists} className="alert alert-warning">
                            Thank you for registering. Please complete your personal profile first so that you can proceed with the application.
                        </CardTitle>

                        <div className="accordion-item">
                            <CardHeader className="h4 border-bottom accordion-header" id="headingOne">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        { collapsed: !col1 }
                                    )}
                                    type="button"
                                    onClick={toggleCol1}
                                    style={{ cursor: "pointer" }}
                                >
                                    Personal Profile
                                </button>
                            </CardHeader>

                            <Collapse isOpen={col1} className="accordion-collapse">
                                <div className="accordion-body">
                                    <CardBody>
                                        <DynamicForm
                                            schema={aidApplicantSchema}
                                            initialValues={applicantData || {}} // Ensure applicantData is ready
                                            onSubmit={(formData) => handleSubmit(formData)}
                                            stepperOn={false}
                                        />
                                    </CardBody>
                                </div>
                            </Collapse>
                        </div>
                    </Card>
                </Col>
            </Row>

            {/* Application List */}
            {!loading && applicantData && applicantExists && (
                <Row>
                    <Col lg={12}>
                        <ApplicationList applicant={applicantData} />
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default Applicant;
