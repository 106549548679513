import React from 'react';
import { Card, CardBody, Form, Button, Col, Row, FormGroup, Label } from 'reactstrap';
import renderField from './RenderField';

const RenderFormNoSteps = ({
    schema = [],  
    formData,
    formErrors,
    handleChange,
    handleSubmit
}) => {
    return (
        <div id="basic-form">
            <Card>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        {schema.map((tab) => (
                            <div key={tab.id}>
                                <h5>{tab.title}</h5>
                                <Row>
                                    {tab.fields.map((field, idx) => (
                                        <Col md={6} sm={12} key={idx}>
                                            <FormGroup className="mb-3">
                                                {renderField(field, handleChange, formData)}
                                                {formErrors[field.name] && (
                                                    <div className="text-danger">{formErrors[field.name]}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </Row>
                                <hr />
                            </div>
                        ))}
                        <Button color="primary" type="submit">Submit</Button>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
};

export default RenderFormNoSteps;
