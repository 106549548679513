import React, { useState, useEffect } from "react";
import { Card, Row, Col, Label } from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { fetchExistingFile, uploadFile, toBase64 } from '../../helpers/Espo/attachment_helper';

const SingleFileUpload = ({ title, name, fileId, fileName, relatedId, onFileUpload }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [existingFile, setExistingFile] = useState(null);
    const [existingFileUrl, setExistingFileUrl] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null); // Track the uploaded file

    useEffect(() => {
        // Reset state if no fileId is provided
        if (!fileId) {
            setSelectedFile(null);
            setExistingFile(null);
            setExistingFileUrl(null);
        }

        if (relatedId && fileId) {
            const loadExistingFile = async () => {
                try {
                    const response = await fetchExistingFile(fileId);
                    if (response) {
                        setExistingFile(response);
                        const fileURL = URL.createObjectURL(response);
                        setExistingFileUrl(fileURL);
                    }
                } catch (error) {
                    console.error("Error loading file:", error);
                }
            };
            loadExistingFile();
        }
    }, [relatedId, fileId]);

    const handleAcceptedFiles = (files) => {
        const file = files[0];
        if (file) {
            setSelectedFile(file);
            onFileUpload(file); // Notify parent component about the file upload
        }
    };

    const handleUpload = async () => {
        let relatedtype = "MMApplicationNew";
        if (!selectedFile) {
            alert("No file selected for upload.");
            return;
        } else {
            console.log("name:", name);
            if (name.toLowerCase().includes("aadhar")) {
                relatedtype = "MMApplicant";
            }
        }

        try {
            const base64File = await toBase64(selectedFile);
            const payload = {
                name: selectedFile.name,
                type: selectedFile.type,
                role: "Attachment",
                relatedType: relatedtype,
                relatedId: relatedId,
                field: name,
                file: base64File,
            };

            console.log("Payload:", payload);

            const response = await uploadFile(payload);
            console.log("File uploaded successfully:", response);

            // Treat uploaded file as the "existing file"
            setUploadedFile(selectedFile);
            setExistingFile(null);
            setSelectedFile(null);

            alert("File uploaded successfully");
        } catch (error) {
            console.error("Error during upload:", error);
            alert(`Upload failed: ${error.message}`);
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setExistingFile(null);
        setUploadedFile(null); // Clear uploaded file
        setExistingFileUrl(null);
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    return (
        <React.Fragment>
            <Label htmlFor="fileUpload"></Label>
            <div className="dropzone">
                <Dropzone onDrop={handleAcceptedFiles} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                        <div style={{ textAlign: "center" }}>
                            <div className="dz-message needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                    <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                                </div>
                                <h4>Drop your file here</h4>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFile && (
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                        <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={selectedFile.name}
                                            src={URL.createObjectURL(selectedFile)}
                                        />
                                    </Col>
                                    <Col>
                                        <Link to="#" className="text-muted font-weight-bold">
                                            {selectedFile.name}
                                        </Link>
                                        <p className="mb-0">
                                            <strong>{formatBytes(selectedFile.size)}</strong>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    )}
                    {existingFile && (
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                        <img width="400" className="avatar-sm rounded bg-light" alt={fileName} src={existingFileUrl} />
                                    </Col>
                                    <Col>
                                        <Link to="#" className="text-muted font-weight-bold">
                                            {fileName}
                                        </Link>
                                        <p className="mb-0">
                                            <strong>{formatBytes(existingFile.size)}</strong>
                                        </p>
                                    </Col>
                                    <Col className="col-auto">
                                        <button onClick={handleRemoveFile} className="btn btn-danger">Remove</button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    )}
                    {uploadedFile && (
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                        <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={uploadedFile.name}
                                            src={URL.createObjectURL(uploadedFile)}
                                        />
                                    </Col>
                                    <Col>
                                        <Link to="#" className="text-muted font-weight-bold">
                                            {uploadedFile.name}
                                        </Link>
                                        <p className="mb-0">
                                            <strong>{formatBytes(uploadedFile.size)}</strong>
                                        </p>
                                    </Col>
                                    <Col className="col-auto">
                                        <button onClick={handleRemoveFile} className="btn btn-danger">Remove</button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    )}
                </div>
            </div>
            <div className="text-center mt-4">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleUpload}
                    disabled={!selectedFile && !existingFile} // Disable if no file is selected or exists
                >
                    Upload
                </button>
            </div>
        </React.Fragment>
    );
};

export default SingleFileUpload;
