import React, { useState, useEffect } from 'react';
import { CardHeader, CardBody, Button, TabContent, TabPane, NavItem, NavLink, Alert } from 'reactstrap';
import classnames from 'classnames';
import renderField from './RenderField';

const RenderFormWithSteps = ({
    schema = [],
    formData,
    formErrors,
    handleChange,
    validateTabFields,
    handleSubmit,
    handleSaveDraft,
    hasUnsavedChanges,
    viewOnly
}) => {
    const [activeTabId, setActiveTabId] = useState(1);
    const [activeTab, setActiveTab] = useState(null);
    const [isTabValid, setIsTabValid] = useState(false); // Track current tab validity
    const [isFormValid, setIsFormValid] = useState(false); // Track whole form validity
    const [alert, setAlert] = useState({ visible: false, message: '', color: 'success' }); // State for alert

    // For loading the correct tab based on the tab ID
    useEffect(() => {
        const foundTab = schema.find(tab => tab.id === activeTabId);
        setActiveTab(foundTab || null);
    }, [activeTabId, schema]);

    // For validating the active tab's fields
    useEffect(() => {
        if (activeTab) {
            const isValid = validateTabFields(activeTab);
            setIsTabValid(isValid);
        }
    }, [activeTab, formData]);

    // For validating the entire form across all tabs
    useEffect(() => {
        const allTabsValid = schema.every((tab) => validateTabFields(tab));
        setIsFormValid(allTabsValid);
    }, [formData, schema]);

    // Alert auto-dismiss
    useEffect(() => {
        if (alert.visible) {
            const timeout = setTimeout(() => setAlert({ ...alert, visible: false }), 3000);
            return () => clearTimeout(timeout);
        }
    }, [alert]);

    // Handle Save Draft with alert/notification
    const handleSaveDraftWithAlert = async (e) => {
        try {
            await handleSaveDraft(e);
            setAlert({ visible: true, message: 'Draft saved successfully!', color: 'success' });
        } catch (error) {
            setAlert({ visible: true, message: 'Failed to save draft. Please try again.', color: 'danger' });
        }
    };

    // Handle Submit with alert/notification
    const handleSubmitWithAlert = async (e) => {
        try {
            await handleSubmit(e);
            setAlert({ visible: true, message: 'Form submitted successfully!', color: 'success' });
        } catch (error) {
            setAlert({ visible: true, message: 'Form submission failed. Please try again.', color: 'danger' });
        }
    };

    // Function to go to the next tab
    const goToNext = async (e) => {
        e.preventDefault();
        try {
            await handleSaveDraft(e);
//            setAlert({ visible: true, message: 'Draft saved successfully!', color: 'success' });
        } catch (error) {
            setAlert({ visible: true, message: 'Failed to save data. Please correct and try again.', color: 'danger' });
        }
        if (isTabValid && activeTabId < schema.length) {
            setActiveTabId(activeTabId + 1);
        }
    };

    // Function to go to the previous tab
    const goToPrev = (e) => {
        e.preventDefault();
        if (activeTabId > 1) {
            setActiveTabId(activeTabId - 1);
        }
    };

    return (
        <div id="basic-pills-wizard" className="twitter-bs-wizard">
            <CardHeader>
                <h4 className="card-title mb-0">{activeTab ? activeTab.title : ''}</h4>
            </CardHeader>
            <CardBody>

                {/* Alert Component */}
                <Alert color={alert.color} isOpen={alert.visible}>
                    {alert.message}
                </Alert>

{/* 
                <div className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                    {schema.map((tab) => (
                        <NavItem key={tab.id}>
                            <NavLink
                                className={classnames({ active: activeTabId === tab.id })}
                                onClick={() => setActiveTabId(tab.id)}
                            >
                                <span className="step-number">{tab.step}</span>
                            </NavLink>
                        </NavItem>
                    ))}
                </div>
*/}
                <TabContent activeTab={activeTabId}>
                    {activeTab && (
                        <TabPane tabId={activeTab.id}>
                                <p>{activeTab.description}</p>
                                {activeTab.haveSections ? (
                                activeTab.sections.map((section, idx) => (
                                    <div key={idx}>
                                        <h5>{section.title}</h5>
                                        {section.fields.map((field, i) => (
                                            <div key={i}>
                                                {renderField(field, handleChange, formData, viewOnly)}
                                                {formErrors[field.name] && (
                                                    <div className="text-danger">{formErrors[field.name]}</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <div>
                                <p>{activeTab.description}</p>
                                {activeTab.fields.map((field, idx) => (
                                    <div key={idx}>
                                        {renderField(field, handleChange, formData, viewOnly)}
                                        {formErrors[field.name] && (
                                            <div className="text-danger">{formErrors[field.name]}</div>
                                        )}
                                    </div>
                                ))}
                                </div>
                            )}
                        </TabPane>
                    )}
                </TabContent>

                <div className="d-flex justify-content-between mt-3">
                    <Button color="secondary" disabled={activeTabId === 1} onClick={goToPrev}>
                        Previous
                    </Button>

                    <Button
                        color="warning"
                        className="mx-auto"
                        disabled={!isTabValid || !hasUnsavedChanges} // Enable only if valid and changes made
                        onClick={handleSaveDraftWithAlert}
                    >
                        Save Draft
                    </Button>

                    {activeTabId === schema.length ? (
                        <Button
                            color="primary"
                            disabled={!isFormValid} // Enable only if form is valid
                            onClick={handleSubmitWithAlert}
                        >
                            Submit
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            disabled={!isTabValid} // Enable Next only if current tab is valid
                            onClick={goToNext}
                        >
                            Next
                        </Button>
                    )}
                </div>
            </CardBody>
        </div>
    );
};

export default RenderFormWithSteps;
