import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_ESPO_API_URL;
const API_KEY = process.env.REACT_APP_ESPO_API_KEY;

// Fetch existing file by fileId
export const fetchExistingFile = async (fileId) => {
    const url = `${API_BASE_URL}/Attachment/file/${fileId}`;
    try {
        const response = await axios.get(url, {
            headers: {
                'x-api-key': API_KEY,
            },
            responseType: 'blob'
        });
        return response;
    } catch (error) {
        console.error("Error fetching existing file:", error);
        throw error;
    }
};

// Upload a file
export const uploadFile = async (filePayload) => {
    const url = `${API_BASE_URL}/Attachment`;
    try {
        const response = await axios.post(url, filePayload, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY,
            },
        });
        // Update file id in related record
        if (filePayload.relatedId) {
            const updateUrl = `${API_BASE_URL}/${filePayload.relatedType}/${filePayload.relatedId}`;
            
            // Dynamically setting the field name in the payload
            const updatePayload = {
                [`${filePayload.field}Id`]: response.id // Use computed property name for dynamic key
            };

            // Making the PUT request to update the related entity
            await axios.put(updateUrl, updatePayload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY, // Ensure you have the API_KEY correctly defined
                },
            });
        }
        
        return response
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
};

// Convert file to Base64
export const toBase64 = (file) => 
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
