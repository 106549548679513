import React from "react";

import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Applicant from "../Applicant/Applicant";
import Application from "../Application/Application";

const Dashboard = () => {
  document.title = "Home | Moeen Memorial";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Moeen Memorial" breadcrumbItem="Home" />
          <Applicant/>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
