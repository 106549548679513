import axios from 'axios';

const API_URL = process.env.REACT_APP_ESPO_API_URL+'/MMApplicant';
const API_KEY = process.env.REACT_APP_ESPO_API_KEY;

// Fetch applicant data by id
export const fetchApplicant = async (applicantId) => {
    try {
        const response = await axios.get(`${API_URL}/${applicantId}`, {
            headers: {
                'x-api-key': API_KEY,
            }
        });
        console.log("fetchApplicant Response - ", response);
        return response; // Return only the data
    } catch (error) {
        console.error('Failed to fetch applicant data:', error);
        throw new Error('Failed to fetch applicant data');
    }
};

// Fetch applicant data by email
export const fetchApplicantByEmail = async (email) => {
    try {
        console.log("API URL - ", `${API_URL}?where[0][type]=equals&where[0][attribute]=emailAddress&where[0][value]=${email}`);
        const response = await axios.get(`${API_URL}?where[0][type]=equals&where[0][attribute]=emailAddress&where[0][value]=${email}`, {
            headers: {
                'x-api-key': API_KEY,
            }
        });
        console.log("fetchApplicantByEmail Response - ", response);
        return response; // Return only the data
    } catch (error) {
        console.error('Failed to fetch applicant data:', error);
        throw new Error('Failed to fetch applicant data');
    }
};

// Create a new applicant
export const createApplicant = async (formData) => {
    try {
        const response = await axios.post(API_URL, formData, {
            headers: {
                'x-api-key': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        console.log("createApplicant Response - ", response);
        return response;
    } catch (error) {
        console.error('Failed to create new applicant:', error);
        throw new Error('Failed to create new applicant');
    }
};

// Update applicant data
export const updateApplicant = async (applicantId, formData) => {
    try {
        const response = await axios.put(`${API_URL}/${applicantId}`, formData, {
            headers: {
                'x-api-key': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        console.log("updateApplicant Response - ", response);
        return response;
    } catch (error) {
        console.error('Failed to update applicant:', error);
        throw new Error('Failed to update applicant');
    }
};
