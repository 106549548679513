import React, { useState, useEffect } from 'react';
import RenderFormWithSteps from './RenderFormWithSteps';
import RenderFormNoSteps from './RenderFormNoSteps';

const DynamicForm = ({ schema, onSubmit, initialValues, stepperOn, viewOnly}) => {
    const [formData, setFormData] = useState(initialValues || {});
    const [formErrors, setFormErrors] = useState({});
    const [isStepper, setIsStepper] = useState(stepperOn || false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Track unsaved changes

    useEffect(() => {
        setFormData(initialValues); // Update initial values
        setIsStepper(stepperOn); // Toggle stepperOn mode
    }, [initialValues, stepperOn]);

    const handleChange = (e) => {
        if (viewOnly) return; // Prevent changes in view-only mode
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        // Track changes
        setHasUnsavedChanges(true);

        // Clear field-specific error when the field is updated and valid
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldValue ? '' : prevErrors[name], // Clear error if value is present
        }));

        setFormData({
            ...formData,
            [name]: fieldValue,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (viewOnly) return; // Prevent submission in view-only mode
        if (validateForm()) {
            const updatedFormData = {
                ...formData,
                status: 'Submitted',
            };
            onSubmit(updatedFormData);
        }
    };

    const handleSaveDraft = (e) => {
        e.preventDefault();
        if (viewOnly) return; // Prevent saving in view-only mode
        if (validateTabFields()) {
            onSubmit(formData);
            setHasUnsavedChanges(false); // Reset change tracking
        }
    };

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        schema.forEach((tab) => {
            if (tab.fields) {
                tab.fields.forEach((field) => {
                    if (field.required && !formData[field.name]) {
                        formIsValid = false;
                        errors[field.name] = 'This field is required';
                    }
                });
            }
        });

        setFormErrors(errors);
        return formIsValid;
    };

    const validateTabFields = (currentTab) => {
        let errors = {};
        let tabIsValid = true;

        if (currentTab?.sections) {
            currentTab.sections.forEach((section) => {
                section.fields.forEach((field) => {
                    if (field.required && !formData[field.name]) {
                        tabIsValid = false;
                        errors[field.name] = 'This field is required';
                    }
                });
            });
        } else if (currentTab?.fields) {
            currentTab.fields.forEach((field) => {
                if (field.required && !formData[field.name]) {
                    tabIsValid = false;
                    errors[field.name] = 'This field is required';
                }
            });
        }

        setFormErrors((prevErrors) => ({ ...prevErrors, ...errors }));
        return tabIsValid;
    };

    return (
        <>
            {isStepper ? (
                <RenderFormWithSteps
                    schema={schema}
                    formData={formData}
                    formErrors={formErrors}
                    handleChange={handleChange}
                    validateTabFields={validateTabFields}
                    handleSubmit={handleSubmit}
                    handleSaveDraft={handleSaveDraft}
                    hasUnsavedChanges={hasUnsavedChanges} 
                    viewOnly={viewOnly} // Pass viewOnly flag to child components
                />
            ) : (
                <RenderFormNoSteps
                    schema={schema}
                    formData={formData}
                    formErrors={formErrors}
                    handleChange={handleChange}
                    validateForm={validateForm}
                    handleSubmit={handleSubmit}
                    viewOnly={viewOnly} // Pass viewOnly flag to child components
                />
            )}
        </>
    );
};

export default DynamicForm;
