import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import { createApplication } from '../../helpers/Espo/application_helper'; // Assuming this helper function exists

const handleCreateApplication = async (applicant) => {
    try {
        const newApplication = {
            mMApplicantId: applicant.id,  // Include any required fields
            status: 'Draft',
            aidNeeded: 0,
            aidGranted: 0,
            aidNeededCurrency: 'INR',
            aidGrantedCurrency: 'INR',
            familysAnnualIncomeCurrency: 'INR',
            tuitionFeesCurrency: 'INR',
            lodgingFeesCurrency: 'INR',
            booksMaterialExpensesCurrency: 'INR',
            otherAidAmountCurrency: 'INR',
            // Any other necessary default values for a new application
        };

        const response = await createApplication(newApplication); // Assuming this returns the new application's data
        return response.id; // Return the ID of the created application
    } catch (error) {
        console.error("Error creating new application:", error);
        alert("Failed to create new application.");
        return null;
    }
};

const ApplicationList = ({ applicant }) => { // Correct destructuring
    const [applications, setApplications] = useState([]);
    const [existingApplication, setExistingApplication] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                if (!applicant || !applicant.id) {
                    throw new Error("Applicant ID is missing.");
                }

                const url = `https://espo.2aana.space/api/v1/MMApplicant/${applicant.id}/mMApplicationNews`;
                const response = await axios.get(url, {
                    headers: {
                        'x-api-key': '3d464d1f4404b17969165f0eaeb45926',
                    }
                });

                console.log("Applications response:", response);

                if (response.list && Array.isArray(response.list) && response.total) {
                    setApplications(response.list);
                    setExistingApplication(true);
                } else {
                    console.error("Unexpected response structure:", response);
                    setExistingApplication(false);
                    setApplications([]);
                }
            } catch (error) {
                console.error("Error fetching applications data:", error);
                setError("Failed to load applications data");
            } finally {
                setLoading(false);
            }
        };

        if (applicant && applicant.id) {
            fetchApplications();
        }
    }, [applicant]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <React.Fragment>
            {!existingApplication ? (
                <Row>
                    <Col md={12}>
                        <Card body>
                            <CardTitle className="mt-1"><h3>Instructions</h3></CardTitle>
                            <div>
                                <p>Please read the filing requirements carefully. Failing to meet these&nbsp;requirements will delay the processing of your application.</p>
                                <ul>
                                    <li>Please fill out the details correctly.</li>
                                    <li>Click Next and fill out each section.</li>
                                    <li>Ensure you have all the following documents (<strong>attested copy</strong> by Gazetted/Appropriate officer) ready to be uploaded. You will not be able to complete the application without them.</li>
                                </ul>
                                <ol>
                                    <li>Photo copy of Aadhar card</li>
                                    <li>Upload Passport Size Photo (2" x 2") of Student</li>
                                    <li>Bonafide certificate or Tuition Fees Receipt</li>
                                    <li>Income certificate authorized by Government (Sarpanch, Mamlatdar etc.)</li>
                                    <li>Previous year/exam result</li>
                                    <li>Current year school/college fee detailed</li>
                                    <li>Bank Passbook or Cancel check</li>
                                </ol>
                            </div>
                            <Button
                                className="btn btn-primary"
                                onClick={async () => {
                                    const newApplicationId = await handleCreateApplication(applicant);

                                    if (newApplicationId) {
                                        // Navigate to the application page with the new applicationId
                                        window.location.href = `../../application?applicationId=${newApplicationId}`;
                                    }
                                }}
                            >
                                Start new Application
                            </Button>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">Applications</h4>
                            </CardHeader>
                            <CardBody>
                                {/* Desktop/Table view */}
                                <div className="d-none d-md-block">
                                    <div id="applicationList">
                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="sort" data-sort="application_number">Application#</th>
                                                        <th className="sort" data-sort="name">Name</th>
                                                        <th className="sort" data-sort="status">Status</th>
                                                        <th className="sort" data-sort="aid_needed">Aid Needed</th>
                                                        <th className="sort" data-sort="aid_granted">Aid Granted</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {applications.map((app) => (
                                                        <tr key={app.id}>
                                                            <td>{app.applicationNumber}</td>
                                                            <td>{app.mMApplicantName}</td>
                                                            <td>{app.status}</td>
                                                            <td>{app.aidNeeded}</td>
                                                            <td>{app.aidGranted ? app.aidGranted : "-"}</td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {!(app.status === "Draft" || app.status === "Missing Information") && (
                                                                        <>
                                                                            <div className="view">
                                                                                <Link to={`/application?applicationId=${app.id}&viewOnly=true`} className="btn btn-sm btn-info view-item-btn">
                                                                                    View
                                                                                </Link>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {(app.status === "Draft" || app.status === "Missing Information") && (
                                                                        <>
                                                                            <div className="edit">
                                                                                <Link to={`/application?applicationId=${app.id}`} className="btn btn-sm btn-warning edit-item-btn">Resume</Link>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {/* Mobile view */}
                                <div className="d-md-none">
                                    <div className="row">
                                        {applications.map((app) => (
                                            <div key={app.id} className="col-12 mb-3">
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle tag="h5">Application #{app.applicationNumber}</CardTitle>
                                                        <p><strong>Name:</strong> {app.mMApplicantName}</p>
                                                        <p><strong>Status:</strong> {app.status}</p>
                                                        <p><strong>Aid Needed:</strong> {app.aidNeeded}</p>
                                                        <p><strong>Aid Granted:</strong> {app.aidGranted ? app.aidGranted : "-"}</p>
                                                        <div className="d-flex gap-2">
                                                            {!(app.status === "Draft" || app.status === "Missing Information") && (
                                                                <>
                                                                    <div className="view">
                                                                        <Link to={`/application?applicationId=${app.id}&viewOnly=true`} className="btn btn-sm btn-info view-item-btn">
                                                                            View
                                                                        </Link>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {(app.status === "Draft" || app.status === "Missing Information") && (
                                                                <>
                                                                    <Link to={`/application?applicationId=${app.id}`} className="btn btn-warning btn-sm">Resume</Link>
                                                                </>
                                                            )}
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default ApplicationList;
