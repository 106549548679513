import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Row, Container } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DynamicForm from '../Forms/DynamicForm';
import { fetchApplication, updateApplication } from '../../helpers/Espo/application_helper';

const Application = () => {
    const [searchParams] = useSearchParams();
    const applicantId = searchParams.get('applicantId');
    const applicationId = searchParams.get('applicationId');
    const viewOnly = searchParams.get('viewOnly')==='true'?true:false;
    const navigate = useNavigate();

    const [application, setApplication] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const aidApplicationSchema = require('../Forms/Schema/AidApplication.json');
    // Sort the schema by the 'id' field
    const sortedSchema = aidApplicationSchema.sort((a, b) => a.id - b.id);


    useEffect(() => {
        const loadApplication = async () => {
            try {
                if (applicationId) {
                    const applicationData = await fetchApplication(applicationId);
                    setApplication(applicationData);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        loadApplication();
    }, [applicationId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Moeen Memorial" breadcrumbItem="Application" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <DynamicForm
                                    schema={sortedSchema}
                                    initialValues={application}
                                    onSubmit={(formData) => {updateApplication(applicationId, formData) }}
                                    stepperOn={true}
                                    viewOnly={(viewOnly)}
                                />
                            </Card>
                            <Button
                                type="button"
                                style={{ float: 'right' }}
                                className="btn btn-primary waves-effect waves-light"
                                color='primary'
                                onClick={() => navigate(-1)}>
                                Back to Dashboard
                            </Button> {/* Go back button */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Application;
