const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Home",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Logout",
        icon: "mdi mdi-logout-variant",
        url: "/logout",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
/*    {
        label: "Forms",
        icon: "ri-eraser-fill",
        issubMenubadge: true,
        bgcolor: "bg-danger",
        badgeValue: "8",
        subItem: [
            { sublabel: "Form Elements", link: "/#" },
            { sublabel: "Form Validation", link: "/#" },
            { sublabel: "Form Advanced Plugins", link: "/#" },
            { sublabel: "Form Editor", link: "/#" },
            { sublabel: "Form File Upload", link: "/#" },
            { sublabel: "Form X-editable", link: "/#" },
            { sublabel: "Form Wizard", link: "/#" },
            { sublabel: "Form Mask", link: "/#" },
        ],
    },
    {
        label: "Tables",
        icon: "ri-table-2",
        subItem: [
            { sublabel: "Basic Tables", link: "/#" },
            { sublabel: "List Js", link: "/#" },
            { sublabel: "React Datatables", link: "/#" },
        ],
    },
    {
        label: "Charts",
        icon: "ri-bar-chart-line",
        subItem: [
            { sublabel: "Apex Charts", link: "/#" },
            { sublabel: "Chartjs Charts", link: "/#" },
            { sublabel: "Re Charts", link: "/#" },
            { sublabel: "Knob Charts", link: "/#" },
            { sublabel: "Sparkline Charts", link: "/#" },
        ],
    },
    {
        label: "Icons",
        icon: "ri-brush-line",
        subItem: [
            { sublabel: "Box Icons", link: "/#" },
            { sublabel: "Material Design", link: "/#" },
            { sublabel: "Dripicons", link: "/#" },
            { sublabel: "Font Awesome", link: "/#" },
        ],
    },
    {
        label: "Maps",
        icon: "ri-map-pin-line",
        subItem: [
            { sublabel: "Google Maps", link: "/#" },
            { sublabel: "Vector Maps", link: "/#" },
        ],
    },
    {
        label: "Multi Level",
        icon: "ri-share-line",
        subItem: [
            { sublabel: "Level 1.1", link: "/#" },
            {
                sublabel: "Level 1.2", link: "/#",
                subMenu: [
                    { title: "Level 2.1" },
                    { title: "Level 2.2" },
                ],
            },
        ],
    },*/
]
export default SidebarData;