import axios from 'axios';

const API_URL = process.env.REACT_APP_ESPO_API_URL+'/MMApplicationNew';
const API_KEY = process.env.REACT_APP_ESPO_API_KEY;

// Fetch application data
export const fetchApplication = async (applicationId) => {
    try {
        const response = await axios.get(`${API_URL}/${applicationId}`, {
            headers: {
                'x-api-key': API_KEY,
            }
        });
        console.log("fetchApplication Response - ", response);
        return response; // Return only the data
    } catch (error) {
        console.error('Failed to fetch application data:', error);
        throw new Error('Failed to fetch application data');
    }
};

// Update application data
export const updateApplication = async (applicationId, formData) => {
    const cleanedData = cleanFormData(formData); // Clean the formData
    console.log("update form data - ", cleanedData);
    try {
        const response = await axios.put(`${API_URL}/${applicationId}`, cleanedData, {
            headers: {
                'x-api-key': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        console.log("updateApplication Response - ", response);
        return response;
    } catch (error) {
        console.error('Failed to update application:', error);
        throw new Error('Failed to update application');
    }
};

// Create a new application
export const createApplication = async (newApplicationData) => {
    //const cleanedData = cleanFormData(newApplicationData); // Clean the newApplicationData
    try {
        const response = await axios.post(API_URL, newApplicationData, {
            headers: {
                'x-api-key': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        console.log("createApplication Response - ", response);
        return response;
    } catch (error) {
        console.error('Failed to create application:', error);
        throw new Error('Failed to create application');
    }
};

// Clean the formData
const cleanFormData = (data) => {
    if (Array.isArray(data)) {
        return data.map(cleanFormData).filter(item => item !== null && item !== undefined);
    } else if (data && typeof data === 'object') {
        return Object.keys(data).reduce((acc, key) => {
            const value = cleanFormData(data[key]);
            if (value !== null && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});
    }
    return data;
};
